import React, { FC, useEffect, useState } from "react";
import "./stepper.scss";
import Button from "../Button";
import Checkbox from "../Checkbox";
import axios from "axios";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { inputPlacholderValue } from "../../utils/helpers";

interface IStepper {
	step: number;
	action: any;
	setStep: any;
}

const Stepper: FC<IStepper> = ({ step, action, setStep }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const [kind, setKind] = useState("");
	const [type, setType] = useState("");
	const [employees, setEmployees] = useState("");
	const [budget, setBudget] = useState("");

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [company, setCompany] = useState("");

	const [product, setProduct] = useState("");
	const [description, setDescription] = useState("");
	const [features, setFeatures] = useState("");

	const [status, setStatus] = useState(false);

	const chooseCheckboxHandler = (set: any, value: string) => {
		set(value);
	};

	const openModalHandler = () => {
		setStep(false);
	};

	const closeModalHandler = () => {
		setStep(4);
	};

	const submitHandler = () => {
		const body = document.querySelector("body");
		if (status && product && description && features) {
			window.scrollTo(0, 0);
			if (body) body.style.overflow = "hidden";
			setLoading(true);
			const obj = {
				kind,
				type,
				employees,
				budget,
				name,
				email,
				phone,
				company,
				product,
				description,
				features,
			};
			axios.defaults.headers.post["Content-Type"] = "application/json";
			axios
				.post("https://formsubmit.co/ajax/azhybek@fortylines.io", obj)
				.then((response) => {
					setLoading(false);
					if (body) body.style.overflow = "auto";
					navigate("/home");
				})
				.catch((error) => console.log(error));
		}
	};

	useEffect(() => {
		document.querySelectorAll(".getStarted input").forEach((el: any) => {
			el.value = "";
		});
	}, [step]);

	const stepper_translation = {
		step_one: {
			step_1_title: t("step_1_title"),
			step_1_subtitle: t("step_1_subtitle"),
			1: t("Marketplace"),
			2: t("CRM-System"),
			3: t("Web-site"),
			4: t("Chat-bots"),
			5: t("Analysis"),
			6: t("Others"),
		},
		step_two: {
			step_2_title: t("step_2_title"),
			step_2_subtitle: t("step_2_subtitle"),
			1: t("Startup"),
			2: t("Existing company"),
		},
		step_three: {
			step_3_title: t("step_3_title"),
			step_3_subtitle: t("step_3_subtitle"),
			1: t("Over-1000"),
		},
		step_four: {
			step_4_title: t("step_4_title"),
			step_4_subtitle: t("step_4_subtitle"),
			1: t("Under $10k"),
			2: t("$10k to $25k"),
			3: t("$25k to $50k"),
			4: t("$50k to $100k"),
			5: t("Over $100k"),
		},
		step_five: {
			step_5_title: t("step_5_title"),
			step_5_subtitle: t("step_5_subtitle"),
		},
		step_six: {
			step_6_title: t("step_6_title"),
			step_6_subtitle: t("step_6_subtitle"),
		},
		checkbox: t("formCheckbox"),
		stepperError: t("stepperError"),
	};

	//  const inputPlacholderValue = (text: any): string => {
	// 	if (text === undefined) return "";
	// 	return text;
	// };

	return (
		<div className="stepper">
			{step === 1 ? (
				<>
					<h2 className="stepper__title">
						{stepper_translation.step_one.step_1_title}
					</h2>
					<p className="stepper__subtitle">
						{stepper_translation.step_one.step_1_subtitle}
					</p>
					<div className="stepper__box">
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setKind}
							prevValue={kind}
							value={stepper_translation.step_one["1"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setKind}
							prevValue={kind}
							value={stepper_translation.step_one["2"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setKind}
							prevValue={kind}
							value={stepper_translation.step_one["3"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setKind}
							prevValue={kind}
							value={stepper_translation.step_one["4"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setKind}
							prevValue={kind}
							value={stepper_translation.step_one["5"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setKind}
							prevValue={kind}
							value={stepper_translation.step_one["6"]}
						/>
						<div className="stepper__button">
							<Button action={kind ? action : null} text={t("Next")} />
						</div>
					</div>
				</>
			) : step === 2 ? (
				<>
					<h2 className="stepper__title">
						{stepper_translation.step_two.step_2_title}
					</h2>
					<p className="stepper__subtitle">
						{stepper_translation.step_two.step_2_subtitle}
					</p>
					<div className="stepper__box">
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setType}
							prevValue={type}
							value={stepper_translation.step_two["1"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							setValue={setType}
							prevValue={type}
							value={stepper_translation.step_two["2"]}
						/>
						<div className="stepper__button">
							<Button action={type ? action : null} text={t("Next")} />
						</div>
					</div>
				</>
			) : step === 3 ? (
				<>
					<h2 className="stepper__title">
						{stepper_translation.step_three.step_3_title}
					</h2>
					<p className="stepper__subtitle">
						{stepper_translation.step_three.step_3_subtitle}
					</p>
					<div className="stepper__box">
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={employees}
							setValue={setEmployees}
							value={"1-10"}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={employees}
							setValue={setEmployees}
							value={"11-100"}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={employees}
							setValue={setEmployees}
							value={"101-1000"}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={employees}
							setValue={setEmployees}
							value={stepper_translation.step_three["1"]}
						/>
						<div className="stepper__button">
							<Button action={employees ? action : null} text={t("Next")} />
						</div>
					</div>
				</>
			) : step === 4 ? (
				<>
					<h2 className="stepper__title">
						{stepper_translation.step_four.step_4_title}
					</h2>
					<p className="stepper__subtitle">
						{stepper_translation.step_four.step_4_subtitle}
					</p>
					<div className="stepper__box">
						{/* <Checkbox
							handler={chooseCheckboxHandler}
							prevValue={budget}
							setValue={setBudget}
							value={stepper_translation.step_four['1']}
						/> */}
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={budget}
							setValue={setBudget}
							value={stepper_translation.step_four["2"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={budget}
							setValue={setBudget}
							value={stepper_translation.step_four["3"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={budget}
							setValue={setBudget}
							value={stepper_translation.step_four["4"]}
						/>
						<Checkbox
							handler={chooseCheckboxHandler}
							prevValue={budget}
							setValue={setBudget}
							value={stepper_translation.step_four["5"]}
						/>
						<div className="stepper__button">
							<Button
								text={t("Next")}
								action={
									budget !== stepper_translation.step_four["1"]
										? action
										: openModalHandler
								}
							/>
						</div>
					</div>
				</>
			) : step === 5 ? (
				<>
					<h2 className="stepper__title">
						{stepper_translation.step_five.step_5_title}
					</h2>
					<p className="stepper__subtitle">
						{stepper_translation.step_five.step_5_subtitle}
					</p>
					<div className="stepper__box">
						<input
							defaultValue={""}
							onChange={(e) => setName(e.target.value)}
							placeholder={inputPlacholderValue(t("formName"))}
							className="stepper__input"
							type="text"
						/>
						<input
							defaultValue={""}
							onChange={(e) => setEmail(e.target.value)}
							placeholder={inputPlacholderValue(t("formEmail"))}
							className="stepper__input"
							type="text"
						/>
						<input
							defaultValue={""}
							onChange={(e) => setPhone(e.target.value)}
							placeholder={inputPlacholderValue(t("formPhone"))}
							className="stepper__input"
							type="text"
						/>
						<input
							defaultValue={""}
							onChange={(e) => setCompany(e.target.value)}
							placeholder={inputPlacholderValue(t("formCompanyName"))}
							className="stepper__input"
							type="text"
						/>
						<div className="stepper__button">
							<Button
								action={name && email && phone && company ? action : null}
								text={t("Next")}
							/>
						</div>
					</div>
				</>
			) : step === 6 ? (
				<>
					<h2 className="stepper__title">
						{stepper_translation.step_six.step_6_title}
					</h2>
					<p className="stepper__subtitle">
						{stepper_translation.step_six.step_6_subtitle}
					</p>
					<div className="stepper__box">
						<input
							defaultValue={""}
							onChange={(e) => setProduct(e.target.value)}
							placeholder={inputPlacholderValue(t("nameProduct"))}
							className="stepper__input"
							type="text"
						/>
						<input
							defaultValue={""}
							onChange={(e) => setDescription(e.target.value)}
							placeholder={inputPlacholderValue(t("productDescription"))}
							className="stepper__input"
							type="text"
						/>
						<input
							defaultValue={""}
							onChange={(e) => setFeatures(e.target.value)}
							placeholder={inputPlacholderValue(t("mostImportant"))}
							className="stepper__input"
							type="text"
						/>
						<label className="stepper__req">
							<input onChange={() => setStatus(!status)} type="checkbox" />
							{stepper_translation.checkbox}
						</label>
						<div className="stepper__button">
							<Button action={submitHandler} text={t("sendInfo")} />
						</div>
					</div>
				</>
			) : (
				<div className="stepper__box">
					<p className="stepper__error">{stepper_translation.stepperError}</p>
					<div className="stepper__button">
						<Button text={t("ChooseAnotherRange")} action={closeModalHandler} />
					</div>
				</div>
			)}
			{loading && (
				<div className="stepper__loader">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="64"
						height="64"
					>
						<path fill="none" d="M0 0h24v24H0z" />
						<path
							d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"
							fill="rgba(107,109,127,1)"
						/>
					</svg>
				</div>
			)}
		</div>
	);
};

export default Stepper;
