import React from 'react'
import './careersContact.scss'
import ContactSection from '../../../../components/ContactSection'
import ContactStep from '../../../../components/ContactStep'
import { useTranslation } from 'react-i18next'

const CareersContact = () => {
	const { t } = useTranslation()
	const w_h_next = t('Steps in the hiring process')
	const steps = [
		{
			title: t('call'),
			subtitle: t('callDescription'),
		},
		{
			title: t('InitialInterwiew'),
			subtitle: t('InitialInterwiewDescription'),
		},
		{
			title: t('TestAssignment'),
			subtitle: t('TestAssignmentDescription'),
		},
		{
			title: t('TechnicalInterview'),
			subtitle: t('TechnicalInterviewDescription'),
		},
		{
			title: t('Hiring'),
			subtitle: t('HiringDescription'),
		},
	]
	return (
		<ContactSection
			text={t('JoinTeamFormSubTitle')}
			type={false}
			title={t('JoinTeamFormTitle')}
		>
			<div className='careersContact__box'>
				<div className='title'>
					Want to become a part of Fortylines team?{' '}
				</div>
				<h4 className='careersContact__title'>{w_h_next}</h4>
				<div className='careersContact__steps'>
					{steps.map((el, idx) => (
						<ContactStep
							number={idx + 1}
							title={el.title}
							subtitle={el.subtitle}
						/>
					))}
				</div>
			</div>
		</ContactSection>
	)
}

export default CareersContact
