import React from 'react'
import { useSwiper } from 'swiper/react'
import './swiperButtons.scss'

const SwiperButtons = () => {
	const swiperButton = useSwiper()

	return (
		<div className='swiperButtons'>
			<button
				onClick={() => swiperButton.slidePrev()}
				className='swiperButtons__left'
			>
				<svg
					width='23'
					height='16'
					viewBox='0 0 23 16'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M0.292391 7.30121C-0.0978551 7.69201 -0.0974054 8.32517 0.293396 8.71542L6.66188 15.0749C7.05268 15.4651 7.68584 15.4647 8.07609 15.0739C8.46634 14.6831 8.46589 14.0499 8.07508 13.6596L2.41421 8.00681L8.06705 2.34594C8.4573 1.95514 8.45685 1.32197 8.06604 0.931725C7.67524 0.541478 7.04208 0.541928 6.65183 0.932729L0.292391 7.30121ZM22.9993 6.99219L0.999291 7.00781L1.00071 9.00781L23.0007 8.99219L22.9993 6.99219Z'
						fill='#5E6062'
					/>
				</svg>
			</button>
			<button
				onClick={() => swiperButton.slideNext()}
				className='swiperButtons__right'
			>
				<svg
					width='23'
					height='16'
					viewBox='0 0 23 16'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M22.7071 8.70711C23.0976 8.31658 23.0976 7.68342 22.7071 7.29289L16.3431 0.928932C15.9526 0.538408 15.3195 0.538408 14.9289 0.928932C14.5384 1.31946 14.5384 1.95262 14.9289 2.34315L20.5858 8L14.9289 13.6569C14.5384 14.0474 14.5384 14.6805 14.9289 15.0711C15.3195 15.4616 15.9526 15.4616 16.3431 15.0711L22.7071 8.70711ZM0 9H22V7H0V9Z'
						fill='#5E6062'
					/>
				</svg>
			</button>
		</div>
	)
}

export default SwiperButtons
