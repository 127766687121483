/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { useRef, useState } from 'react'
import './work.scss'
import SectionTitle from '../../../../components/SectionTitle'
import { useTranslation } from 'react-i18next'

const WorkSection = () => {
	const { t }: any = useTranslation()

	const dataEst = [
		{
			title: t('WorkSectionItem1Title'),
			desc: t('WorkSectionItem1Desc'),
			parts: [
				t('WorkSectionItem1Parts1'),
				t('WorkSectionItem1Parts2'),
				t('WorkSectionItem1Parts3'),
				t('WorkSectionItem1Parts4'),
			],
			res: t('WorkSectionItem1Res'),
		},
		{
			title: t('WorkSectionItem2Title'),
			desc: t('WorkSectionItem2Desc'),
			parts: [
				t('WorkSectionItem2Parts1'),
				t('WorkSectionItem2Parts2'),
				t('WorkSectionItem2Parts3'),
			],
			res: t('WorkSectionItem2Res'),
		},
		{
			title: t('WorkSectionItem3Title'),
			desc: t('WorkSectionItem3Desc'),
			parts: [
				t('WorkSectionItem3Parts1'),
				t('WorkSectionItem3Parts2'),
				t('WorkSectionItem3Parts3'),
			],
			res: t('WorkSectionItem3Res'),
		},
		{
			title: t('WorkSectionItem4Title'),
			desc: t('WorkSectionItem4Desc'),
			parts: [t('WorkSectionItem4Parts1')],
			res: t('WorkSectionItem4Res'),
		},
	]

	const [content, setContent] = useState(1)
	const [lastType, setLastType] = useState(1)

	let myTimeout: any = null

	const firstCardRef: any = useRef()
	const secondCardRef: any = useRef()
	const thirdCardRef: any = useRef()

	const toggleAnimateHandler = async () => {
		;[firstCardRef, secondCardRef, thirdCardRef].map((el) => {
			if (
				[...el.current.classList].includes('work__cards_item-opacity')
			) {
				clearTimeout(myTimeout)
				myTimeout = null
				el.current.classList.remove('work__cards_item-opacity')
			} else {
				el.current.classList.add('work__cards_item-opacity')
			}
		})
		;[firstCardRef, secondCardRef, thirdCardRef].map((el, idx) => {
			if (
				[...el.current.classList].includes(
					`work__cards_item-animate${idx + 1}`,
				)
			) {
				clearTimeout(myTimeout)
				myTimeout = null
				el.current.classList.remove(
					`work__cards_item-animate${idx + 1}`,
				)
			} else {
				el.current.classList.add(`work__cards_item-animate${idx + 1}`)
			}
		})
	}

	const removeAnimateHandler = () => {
		;[firstCardRef, secondCardRef, thirdCardRef].map((el, idx) => {
			if (
				[...el.current.classList].includes(
					`work__cards_item-animate${idx + 1}`,
				)
			) {
				el.current.classList.remove(
					`work__cards_item-animate${idx + 1}`,
				)
				clearTimeout(myTimeout)
				myTimeout = null
			}
		})
		;[firstCardRef, secondCardRef, thirdCardRef].map((el) => {
			if (
				[...el.current.classList].includes('work__cards_item-opacity')
			) {
				el.current.classList.remove('work__cards_item-opacity')
				clearTimeout(myTimeout)
				myTimeout = null
			}
		})
	}

	const changeContentHandler = (type: number) => {
		setLastType(type)
		if (content !== type) {
			toggleAnimateHandler().then(() => {
				myTimeout = setTimeout(() => removeAnimateHandler(), 1200)
			})
			setContent(type)
		}
		document.querySelectorAll('.work__nav_item').forEach((item, idx) => {
			if (type > idx) {
				item.classList.add('work__nav_item-active')
			} else {
				item.classList.remove('work__nav_item-active')
			}
		})
	}

	const switchClass = (propClass: number) => {
		switch (propClass) {
			case content:
				return 'work__nav_item-activeSubtitle'
			default:
				return ''
		}
	}

	return (
		<section className={'work'}>
			<div className='container'>
				<SectionTitle text={t('WorkSectionTitle')} />
				<div className='work__nav'>
					<div
						onClick={() => changeContentHandler(1)}
						className={`work__nav_item work__nav_item-first work__nav_item-active`}
					>
						<h4
							className={`work__nav_item-title ${switchClass(1)}`}
						>
							{t('WorkSectionItem1')}
						</h4>
					</div>
					<div
						onClick={() => changeContentHandler(2)}
						className={`work__nav_item work__nav_item-second`}
					>
						<h4
							className={`work__nav_item-title ${switchClass(2)}`}
						>
							{t('WorkSectionItem2')}
						</h4>
					</div>
					<div
						onClick={() => changeContentHandler(3)}
						className={`work__nav_item work__nav_item-third`}
					>
						<h4
							className={`work__nav_item-title ${switchClass(3)}`}
						>
							{t('WorkSectionItem3')}
						</h4>
					</div>
					<div
						onClick={() => changeContentHandler(4)}
						className={`work__nav_item work__nav_item-fourth`}
					>
						<h4
							className={`work__nav_item-title ${switchClass(4)}`}
						>
							{t('WorkSectionItem4')}
						</h4>
					</div>
				</div>
				<div className='work__cards'>
					<div
						ref={firstCardRef}
						className={`work__cards_item work__cards_item-first`}
					>
						<h3 className='work__cards_item-title'>
							{dataEst[content - 1].title}
						</h3>
						<p>{dataEst[content - 1].desc}</p>
					</div>
					<div
						ref={secondCardRef}
						className={`work__cards_item work__cards_item-second`}
					>
						<h3 className='work__cards_item-title'>
							{t('Participants')}
						</h3>
						{dataEst[content - 1].parts.map((item) => (
							<p className='work__cards_item-li'>{item}</p>
						))}
					</div>
					<div
						ref={thirdCardRef}
						className={`work__cards_item work__cards_item-third`}
					>
						<h3 className='work__cards_item-title'>
							{t('Result')}
						</h3>
						<p>{dataEst[content - 1].res}</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default WorkSection
