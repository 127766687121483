import React from 'react';

const Location = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M12.9993 2.1665C8.80685 2.1665 5.41602 5.55734 5.41602 9.74984C5.41602 15.4373 12.9993 23.8332 12.9993 23.8332C12.9993 23.8332 20.5827 15.4373 20.5827 9.74984C20.5827 5.55734 17.1918 2.1665 12.9993 2.1665ZM7.58268 9.74984C7.58268 6.75984 10.0093 4.33317 12.9993 4.33317C15.9893 4.33317 18.416 6.75984 18.416 9.74984C18.416 12.8698 15.296 17.539 12.9993 20.4532C10.746 17.5607 7.58268 12.8373 7.58268 9.74984ZM10.291 9.74984C10.291 8.25407 11.5036 7.0415 12.9993 7.0415C13.9669 7.0415 14.861 7.55771 15.3448 8.39567C15.8286 9.23363 15.8286 10.266 15.3448 11.104C14.861 11.942 13.9669 12.4582 12.9993 12.4582C11.5036 12.4582 10.291 11.2456 10.291 9.74984Z"
                  fill="#4583E2"/>
        </svg>

    );
};

export default Location;