import React from 'react';
import World from '../../../../assets/img/world.png';
import Wordl2 from '../../../../assets/img/world2.png';
import IntroSection from "../../../../components/IntroSection";
import {useRecoilState} from "recoil";
import {ThemeState} from "../../../../store/ThemeState";
import {useTranslation} from "react-i18next";

const AboutIntro = () => {
    const {t} = useTranslation()
    const [theme] = useRecoilState(ThemeState)

    return (
        <IntroSection link={'https://tapintech.io/applications'}
                      subtitle={t("AboutIntroSubTitle")} actionText={t("AboutIntroJoinButton")}
                      title={t("AboutIntroTitle")} img={theme === 'dark' ? World : Wordl2}/>
    );
};

export default AboutIntro;