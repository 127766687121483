/* eslint-disable react/jsx-no-target-blank */
import { FC } from 'react'
import './teamCard.scss'
import Linkedin from '../../assets/icons/linkedin.png'

interface ITeamCard {
	name: string
	position: string
	img: any
	linkedin?: string
}

const TeamCard: FC<ITeamCard> = ({ name, position, img, linkedin }) => {
	return (
		<div className='teamCard'>
			<div
				style={{ background: `url(${img}) center/cover no-repeat` }}
				className='teamCard__img'
			></div>
			<div className='teamCard__info'>
				<h3 className='teamCard__name'>{name}</h3>
				<p className='teamCard__subtitle'>{position}</p>
				<a target={'_blank'} href={`${linkedin}`}>
					<img className='teamCard__link' src={Linkedin} alt='' />
				</a>
			</div>
		</div>
	)
}

export default TeamCard
