import React, {FC} from 'react';
import './sectionTitle.scss';

interface ISectionTitle {
    text: string
}

const SectionTitle: FC<ISectionTitle> = ({text}) => {
    return (
        <h2 className="sectionTitle">{text}</h2>
    );
};

export default SectionTitle;