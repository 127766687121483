import { useRef } from 'react'
import './header.scss'
import { NavLink, useNavigate } from 'react-router-dom'
import Logo from '../../components/Logo/Logo'
import Switch from '../../components/Switch'
import { useRecoilState } from 'recoil'
import { burgerMenuState, languageState } from '../../store/states'
import { useTranslation } from 'react-i18next'

const Header = () => {
	const [lang, setLang] = useRecoilState(languageState)

	const [burger, setBurger] = useRecoilState(burgerMenuState)

	const headerRef: any = useRef(null)

	const { t, i18n }: any = useTranslation()

	const changeLanguage = (props: string) => {
		setLang(props)
		i18n.changeLanguage(props)
	}

	const scrollTop = () => {
		window.scroll(0, 0)
		setBurger(false)
	}

	const navigate = useNavigate()
	const scrollToElement = () => {
		navigate('/home')
		setBurger(false)
	}

	return (
		<header ref={headerRef} className={`header`}>
			<div className='container'>
				<NavLink className='header__logo' to={'/home'}>
					<Logo />
				</NavLink>
				<div className={'header__tools'}>
					<div
						onClick={() => setBurger(!burger)}
						className={`header__burgerButton ${
							burger ? 'header__burgerButtonActive' : ''
						}`}
					>
						<div></div>
						<div></div>
					</div>
					<div
						className={`header__burger ${
							burger ? 'activeMenu' : ''
						}`}
					>
						<nav className={'header__tools_menu'}>
							<NavLink
								onClick={scrollTop}
								className={'header__tools_menu-link'}
								to={'/home'}
							>
								{t('headerHome')}
							</NavLink>
							<NavLink
								onClick={scrollTop}
								className={'header__tools_menu-link'}
								to={'/about'}
							>
								{t('headerAbout')}
							</NavLink>
							<a
								className={'header__tools_menu-link'}
								href={'#services'}
								onClick={scrollToElement}
							>
								{t('headerServices')}
							</a>
							<a
								className={'header__tools_menu-link'}
								href={'#projects'}
								onClick={scrollToElement}
							>
								{t('headerProjects')}
							</a>
							<NavLink
								onClick={scrollTop}
								className={'header__tools_menu-link'}
								to={'/careers'}
							>
								{t('headerCareers')}
							</NavLink>
						</nav>
						<a
							className={'header__tools_tel'}
							href='tel:+996998446610'
						>
							<i className='ri-phone-fill' />
							+996 998 446 610
						</a>
						<div className='header__tools_lang'>
							<button
								onClick={() => changeLanguage('En')}
								className={`header__tools_lang-button ${
									lang === 'En'
										? ''
										: 'header__tools_lang-button-disabled'
								}`}
							>
								EN
							</button>
							<button
								onClick={() => changeLanguage('De')}
								className={`header__tools_lang-button ${
									lang === 'De'
										? ''
										: 'header__tools_lang-button-disabled'
								}`}
							>
								DE
							</button>
							<button
								onClick={() => changeLanguage('Ru')}
								className={`header__tools_lang-button ${
									lang === 'Ru'
										? ''
										: 'header__tools_lang-button-disabled'
								}`}
							>
								RU
							</button>
						</div>
						<Switch />
					</div>
				</div>
			</div>
		</header>
	)
}

export default Header
