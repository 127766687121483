import './footer.scss'
import Telephone from '../../assets/icons/Telephone'
import Mail from '../../assets/icons/Mail'
import Location from '../../assets/icons/Location'
import Linkedin from '../../assets/icons/Linkedin'
import Instagram from '../../assets/icons/Instagram'
import Logo from '../../components/Logo/Logo'
import Line from '../../components/Line/Line'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Index = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const scrollTop = () => {
		window.scroll(0, 0)
	}
	const scrollToElement = () => {
		navigate('/home')
	}

	const texts = {
		pages: t('pages'),
		home: t('headerHome'),
		about: t('headerAbout'),
		services: t('headerServices'),
		projects: t('headerProjects'),
		careers: t('headerCareers'),
		socialMedia: t('socialMedia'),
		contacts: t('Contacts'),
		address: t('address'),
	}

	return (
		<footer className={'footer'}>
			<div className='container'>
				<div className={'footer__main'}>
					<Logo />
					<div className='footer__main_right'>
						<ul className={'footer__ul'}>
							<li className='footer_title'>{texts.pages}</li>
							<NavLink onDoubleClick={scrollTop} to={'/home'}>
								<li>{texts.home}</li>
							</NavLink>
							<NavLink onDoubleClick={scrollTop} to={'/about'}>
								<li>{texts.about}</li>
							</NavLink>
							<a href={'#services'} onClick={scrollToElement}>
								<li>{texts.services}</li>
							</a>
							<a href={'#projects'} onClick={scrollToElement}>
								<li>{texts.projects}</li>
							</a>
							<NavLink onDoubleClick={scrollTop} to={'/careers'}>
								<li>{texts.careers}</li>
							</NavLink>
						</ul>
						<ul>
							<li className='footer_title'>{texts.contacts}</li>
							<a href='tel:+996998446610'>
								<li>
									<Telephone />
									<span>+996 (998) 446 610 </span>
								</li>
							</a>
							<a href='mailto:people@fortylines.io'>
								<li>
									<Mail />
									<span>people@fortylines.io</span>
								</li>
							</a>
							<a href='https://2gis.kg/bishkek/geo/15763234350985274?m=74.60263%2C42.884587%2F16'>
								<li>
									<Location />
									<span>{texts.address}</span>
								</li>
							</a>
						</ul>
						<ul>
							<li className='footer_title'>
								{texts.socialMedia}
							</li>
							<li>
								<a
									target={'_blank'}
									href='https://www.linkedin.com/company/fortylines-io/'
									rel='noreferrer'
								>
									<Linkedin />
								</a>
								<a
									target={'_blank'}
									href='https://www.instagram.com/fortylinesio/'
									rel='noreferrer'
								>
									<Instagram />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<Line />
			<div className={'container'}>
				<div className={'footer__end'}>
					<span>
						Copyright © {new Date().getFullYear()}. Fortylines IO. All rights reserved.
					</span>
				</div>
			</div>
		</footer>
	)
}

export default Index
