import React from 'react';
import './weSection.scss';
import SectionTitle from "../../../../components/SectionTitle";
import {useTranslation} from "react-i18next";

const WeSection = () => {
    const {t} = useTranslation()
    const title = t("AboutIntroWhoWeText")
    const sub_title = t("AboutIntroWhoWeSubText")
    return (
        <section className="weSection">
            <SectionTitle text={t("AboutIntroWhoWe")}/>
            <p className="weSection__subtitle weSection__subtitle-margin">{title}</p>
            <p className="weSection__subtitle">{sub_title}</p>
        </section>
    );
};

export default WeSection;