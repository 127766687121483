import React from 'react'
import CareersIntro from './sections/CareersIntro'
import CareersJobs from './sections/CareersJobs'
import CareersContact from './sections/CareersContact'
import FindJob from './sections/FindJob'

const Careers = () => {
	return (
		<>
			<CareersIntro />
			{/* <FramerRight div={<CareersJobs />} /> */}
			<FindJob />
			<CareersContact />
		</>
	)
}

export default Careers
