import IntroSection from './sections/AboutIntro'
import WeSection from './sections/WeSection'
import ValueSection from './sections/ValuesSection'
import TeamSection from './sections/TeamSection'
import JourneySection from './sections/JourneySection'

const About = () => {
	return (
		<>
			<IntroSection />
			<WeSection />
			<ValueSection />
			<TeamSection />
			<JourneySection />
		</>
	)
}

export default About
