import React from 'react';
import {MapFortylines} from "../../../../components/map/mapGis";
import ContactSection from "../../../../components/ContactSection";
import {useTranslation} from "react-i18next";


const Index = () => {
    const {t} = useTranslation()
    return (
        <ContactSection text={t("ContactSectionText")} type={true} title={t("ContactSectionTitle")}>
            <MapFortylines/>
        </ContactSection>
    );
};

export default Index;