import {atom} from 'recoil';

export const messageState = atom({
    key: 'PopUpMessage',
    default: '',
});

export const languageState = atom({
    key: 'languageState',
    default: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'Eng',
});

export const modalState = atom({
    key: 'modalWindow',
    default: false,
});

export const burgerMenuState = atom({
    key: 'burgerMenu',
    default: false,
});