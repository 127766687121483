import React, { useRef } from 'react'
import './getStarted.scss'
import Button from '../Button'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
// import {keyboardAnimation} from "../../utils/animation";

const GetStarted = () => {
	const { t } = useTranslation()
	const ref: any = useRef()
	const textTranslitions = {
		get_started: t('GetStarted'),
		ready_get_started: t('GetStartedTitle'),
		getStartedDescription: t('GetStartedDescription'),
	}
	// useEffect(() => {
	//     keyboardAnimation(ref, 'It is a pleasure to cooperate with you!')
	//     // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const navigate = useNavigate()
	const navigateHandler = () => {
		navigate('/start')
	}
	return (
		<div className={'getStartedBox'}>
			<h2 className={'getStartedBox__title'}>
				{textTranslitions.ready_get_started}
			</h2>
			<span ref={ref} className='getStartedBox__info animation-title' />
			<span className={'getStartedBox__description'}>
				{textTranslitions.getStartedDescription}
			</span>
			<div className='getStartedBox__button'>
				<Button
					action={navigateHandler}
					text={textTranslitions.get_started}
				/>
			</div>
		</div>
	)
}

export default GetStarted
