import React from 'react';
import Planet from '../../../../assets/img/planet.png';
import Planet2 from '../../../../assets/img/planet2.png';
import IntroSection from "../../../../components/IntroSection";
import {useRecoilState} from "recoil";
import {ThemeState} from "../../../../store/ThemeState";
import {useTranslation} from "react-i18next";

const CareersIntro = () => {
    const {t} = useTranslation()
    const [theme] = useRecoilState(ThemeState);

    return (
        <IntroSection link={'#careersJobs'} subtitle={t('CareersSubTitle')} actionText={t('CareersFindJob')} title={t('CareersTitle')}
                      img={theme === "dark" ? Planet : Planet2}/>
    );
};

export default CareersIntro;