import './switch.scss'
import { useRecoilState } from 'recoil'
import { ThemeState } from '../../store/ThemeState'

const Switch = () => {
	const [circleStatus, setCircleStatus] = useRecoilState(ThemeState)

	return (
		<div
			onClick={() =>
				setCircleStatus(`${circleStatus === 'dark' ? 'light' : 'dark'}`)
			}
			className='switch'
		>
			<div
				className={`switch__circle ${
					circleStatus === 'light'
						? 'switch__circle-active'
						: 'switch__circle-disabled'
				}`}
			/>
		</div>
	)
}

export default Switch
