import OurProjects from './Sections/OurProjects'
import HomeIntro from './Sections/HomeIntro'
import ServicesSection from './Sections/Services'
import OurClients from './Sections/OurClients'
import HomeGetStarted from './Sections/HomeGetStarted'
import ContactMap from './Sections/ContactMap'
import BenefitsSection from './Sections/Benefits'
import WorkSection from './Sections/Work'

const Home = () => {
	return (
		<main>
			<HomeIntro />
			<ServicesSection />
			<BenefitsSection />
			<WorkSection />
			<OurProjects />
			<OurClients />
			<HomeGetStarted />
			<ContactMap />
		</main>
	)
}

export default Home
