import i18n from "i18next";
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from '../assets/locales/en/translation.json';
import translationDe from '../assets/locales/de/translation.json';
import translationRu from '../assets/locales/ru/translation.json';

// the translations
const resources = {
    "De": {
        translation: translationDe
    },
    "En": {
        translation: translationEN
    },
    "Ru": {
        translation: translationRu
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'En',
        whiteList: ["De", "En", 'Ru'],
        debug: true,
        detection: {
            order: ["localStorage", "cookie"],
            caches: ["localStorage", "cookie"],
        },
        interpolation: {
            escapeValue: false
        },
    });


export default i18n;