import { FC } from 'react'
import './checkbox.scss'

interface ICheckbox {
	value: string
	prevValue: string
	setValue: any
	handler: any
}

const Checkbox: FC<ICheckbox> = ({ value, prevValue, setValue, handler }) => {
	console.log(prevValue)
	console.log(value)

	const checkboxHandler = () => {
		handler(setValue, value)
	}
	return (
		<div onClick={checkboxHandler} className='checkbox'>
			<div className='checkbox__circle'>
				{value === prevValue && (
					<div className='checkbox__circle_status' />
				)}
			</div>
			<p className={value === prevValue ? 'checkbox__titlechecked' : 'checkbox__title'}>
				{value}
			</p>
		</div>
	)
}

export default Checkbox
