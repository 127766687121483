import React, { useState } from 'react'
import './getStarted.scss'
import Logo from '../../components/Logo/Logo'
import Stepper from '../../components/Stepper'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const GetStarted = () => {
	const { t } = useTranslation()
	const [step, setStep] = useState(1)

	const prevStepHandler = () => {
		window.history.back()
	}

	const nextStepHandler = () => {
		setStep((prevState) => (prevState < 6 ? prevState + 1 : prevState))
	}

	const back_ = t('Back')

	return (
		<section className='getStarted'>
			<div className='container'>
				<div className='getStarted__header'>
					<Link to='/home'>
						<Logo />
					</Link>
					<button onClick={prevStepHandler}>
						<svg
							width='28'
							height='6'
							viewBox='0 0 28 6'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M28 3L23 0.113249V5.88675L28 3ZM0 3.5H23.5V2.5H0V3.5Z'
								fill='#4583E2'
							/>
						</svg>
						{back_}
					</button>
				</div>
				<Stepper
					setStep={setStep}
					action={nextStepHandler}
					step={step}
				/>
			</div>
		</section>
	)
}

export default GetStarted
