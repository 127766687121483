/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { load } from '@2gis/mapgl'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { messageState } from '../../store/states'
import { ThemeState } from '../../store/ThemeState'
import DarkMap from '../../assets/img/black_map.png'
import WhiteMap from '../../assets/img/white_map.png'
import './map.scss'

// const MapWrapper = React.memo(
//     () => {
//         // return <div id="map-container" style={{width: '100%', height: '100%'}}/>
//     },
//     () => true,
// );

const styles = {
	light: '0838ebb3-2867-4647-8c1a-176ed4d4dcb2',
	dark: '528eae43-fbcb-4a96-b444-e2ee5dcef7d8',
}

export const MapFortylines = () => {
	const [message, setMessage] = useRecoilState(messageState)
	const [theme, setTheme] = useRecoilState(ThemeState)

	useEffect(() => {
		let map
		let marker
		load().then((mapglAPI) => {
			map = new mapglAPI.Map('map-container', {
				center: [74.60263, 42.884587],
				zoom: 7,
				style: theme === 'dark' ? styles.dark : styles.light,
				key: '7c70a320-711f-4e72-99d6-54bccc526eab',
			})
			marker = new mapglAPI.Marker(map, {
				coordinates: [74.60263, 42.884587],
			})
			marker.on('click', (e) => {
				let textField = document.createElement('textarea')
				textField.innerText = 'Zhibek-zholu 479a'
				document.body.appendChild(textField)
				textField.select()
				document.execCommand('copy')
				textField.remove()

				setMessage('Address copied ✓')
			})
		})
		return () => map && map.destroy()
	}, [theme])

	return theme === 'dark' ? (
		<div className='wrapper_map_img'>
			<img className='img' width='100%' src={WhiteMap} alt='' />
		</div>
	) : (
		<div className='wrapper_map_img'>
			<img className='img' width='100%' src={DarkMap} alt={'map'} />
		</div>
	)
}
