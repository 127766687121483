import React from 'react';
import AnimatedNumbers from "react-animated-numbers";

const NumberCounter = ({value}) => {
    return (
        <AnimatedNumbers
            includeComma
            animateToNumber={value}
            fontStyle={{fontSize: 72}}
            configs={[
                {mass: 1, tension: 20, friction: 10},
            ]}
        />
    );
};

export default NumberCounter;