import { FC } from 'react'
import './introSection.scss'
import Button from '../Button'
import { useNavigate } from 'react-router'

interface IIntro {
	title?: any
	subtitle: string
	actionText: string
	img: any
	action?: any
	children?: any
	link?: string
}

const IntroSection: FC<IIntro> = ({
	title,
	subtitle,
	actionText,
	img,
	action,
	children,
	link,
}) => {
	const navigate = useNavigate()

	const reLink = () => {
		navigate('/careers')
	}

	return (
		<section className='introSection'>
			<div className='container'>
				<div className='introSection__content'>
					{children ? (
						children
					) : (
						<h1 className='introSection__title'>{title}</h1>
					)}
					<p className='introSection__subtitle'>{subtitle}</p>
					<div className='introSection__button'>
						{link ? (
							<a
								// onClick={() => (link ? reLink() : '')}
								// href={link ? `#${link?.split('#')[1]}` : ''}
href={"https://tapintech.io/applications"}
							>
								<Button action={action} text={actionText} />
							</a>
						) : (
							<a

							>
								<Button action={action} text={actionText} />
							</a>
						)}
					</div>
				</div>
				<img className='introSection__img' src={img} alt='' />
			</div>
		</section>
	)
}

export default IntroSection
