import React, {FC} from 'react';
import './journeyItem.scss';

interface IJourneyItem {
    date: string,
    text: string
}

const JourneyItem: FC<IJourneyItem> = ({date, text}) => {
    return (
        <div className="journeyItem">
            <div className="journeyItem__year">
                <h2 className="journeyItem__year_title">{date}</h2>
            </div>
            <p className="journeyItem__text">{text}</p>
        </div>
    );
};

export default JourneyItem;