import React, {FC} from 'react';
import './servicesCard.scss';

interface IServicesCard {
    title: string,
    subtitle: string,
    type: string,
    img: any
}

const ServicesCard: FC<IServicesCard> = ({title, subtitle, img, type}) => {
    return (
        <div className="servicesCard">
            <div className={`servicesCard__icon servicesCard__icon-${type}`}>
                <img src={img} alt=""/>
            </div>
            <h3 className="servicesCard__title">{title}</h3>
            <p className="servicesCard__description">{subtitle}</p>
        </div>
    );
};

export default ServicesCard;