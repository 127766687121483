/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import './PopUp.scss'
import { useRecoilState } from 'recoil'
import { messageState } from '../../store/states'

const Index = () => {
	const [message, setMessage]: any = useRecoilState(messageState)

	useEffect(() => {
		setTimeout(() => {
			setMessage(null)
		}, 2000)
	}, [])

	return (
		<div className='PopUpModal Down'>
			<h2>{message.split('/')[0]}</h2>
			<span>{message.split('/')[1]}</span>
		</div>
	)
}

export default Index
