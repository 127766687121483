import { useState, FC, ChangeEvent } from "react";
import { useRecoilState } from "recoil";
import { ThemeState } from "../../store/ThemeState";
// import { ReactComponent as Upload } from '../../assets/icons/upload.svg'
import "./filePicker.css";
import { useTranslation } from "react-i18next";
import { TfiUpload } from "react-icons/tfi";

type IFilePicker = {
	onChooseFile: (file: string) => void;
	file: any;
	setFile: any;
};

const FilePicker: FC<IFilePicker> = ({ setFile, file }) => {
	const { t } = useTranslation();
	const [them] = useRecoilState(ThemeState);

	const onDrop = (e: any) => {
		console.log("🚀 ~ file: index.tsx:21 ~ onDrop ~ e", e.target.files)
		
		setFile(e.target.files);
	};

	const texts = {
		choose: t("choose"),
	};

	return (
		<div className="imagePicker">
			<div className="dropzoneWrapper">
				<input required={true} onChange={onDrop} type="file" />
			</div>
			<div
				style={{
					background:
						them === "light" ? "#EFF0F1" : "rgba(120, 136, 152, 0.04)",
				}}
				className={"input"}
			>
				<span className="file-name">
					{file[0]?.name ? file[0]?.name : texts.choose}
				</span>
				<TfiUpload color="#797979" size={17} />
			</div>
		</div>
	);
};

export default FilePicker;
