/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import './vacancy.scss'
import { useParams } from 'react-router-dom'
import HrPhoto from '../../assets/img/HR-Alisa.jpg'
import Button from '../../components/Button'
import Form from '../../components/Form'
import { useRecoilState } from 'recoil'
import { languageState, modalState } from '../../store/states'
import circle from './circle.svg'
import { vacanciesData } from '../../assets/data/VacanciesData'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

const Vacancy = () => {
	const { t } = useTranslation()
	const [lang] = useRecoilState(languageState)
	const current_lang = lang ? lang : 'En'
	const [modal, setModal] = useRecoilState(modalState)

	const openModalHandler = () => {
		setModal(true)
	}
	const closeModalHandler = () => {
		setModal(false)
	}

	const { id } = useParams()

	const [idx]: any = useState(id)
	const [users, setUser] = useState(
		vacanciesData[current_lang as keyof typeof vacanciesData],
	)
	const navigate = useNavigate()

	useEffect(() => {
		setUser(vacanciesData[current_lang as keyof typeof vacanciesData])
	}, [lang])

	const nav = () => [navigate('/careers')]
	const about = t('VacancyAboutFortylines')
	const aboutDescription = t('VacancyAboutFortylinesDescription')
	// const hr_name = t('SanjarTurarbekovich')
	const hr_name = 'Alisa Omurbekova'
	const requirments = t('Requirements')
	const we_offer = t('WhatWeOffer')
	const responibility = t('Responsibilities')
	const location = t('Location')
	const tech = t('Technologies')
	const all_vac = t('allVacancies')
	const sendCV = t('sendRequest')
	return (
		<section className='vacancy'>
			<div className='container'>
				<a
					href='#careersJobs'
					onClick={() => nav()}
					className='vacancy__link'
				>
					<svg
						width='28'
						height='6'
						viewBox='0 0 28 6'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M28 3L23 0.113249V5.88675L28 3ZM0 3.5H23.5V2.5H0V3.5Z'
							fill='#4583E2'
						/>
					</svg>
					{all_vac}
				</a>
				<div className='vacancy__content'>
					<div className='vacancy__content_left'>
						<h2 className='vacancy__content_title'>
							{users[idx]?.position}
						</h2>
						<div className='vacancy__content_info'>
							<div className='vacancy__content_info-item'>
								<h5>{location}:</h5>
								<p className='vacancy__content_text'>
									{users[idx]?.type}
								</p>
							</div>
							<div className='vacancy__content_info-item'>
								<h5>{tech}:</h5>
								<p className='vacancy__content_text'>
									{users[idx]?.techs.join(', ')}
								</p>
							</div>
						</div>

						<div className='vacancy__content_about'>
							<h3>{about}</h3>
							<p className='vacancy__content_text'>
								{aboutDescription}
							</p>
						</div>

						<div className='vacancy__content_req'>
							<h3>{requirments}</h3>
							<ul>
								{users[idx].requirements.map((item) => (
									<li className='vacancy__content_text'>
										<img src={circle} alt='' />
										{item}
									</li>
								))}
							</ul>
						</div>
						<div className='vacancy__content_req'>
							<h3>{we_offer}</h3>
							<ul>
								{users[idx].offers.map((item) => (
									<li className='vacancy__content_text'>
										{item}
									</li>
								))}
							</ul>
						</div>
						{users[idx].resp ? (
							<div className='vacancy__content_req'>
								<h3>{responibility}</h3>
								<ul>
									{users[idx].resp?.map((item) => (
										<li className='vacancy__content_text'>
											<img src={circle} alt='' />
											{item}
										</li>
									))}
								</ul>
							</div>
						) : (
							''
						)}
					</div>
					<div
						style={{ position: 'sticky', top: '25%' }}
						className='vacancy__content_right'
					>
						{/*<img width='100%' height={'250px'} className="vacancy__content_right-img" src={HrPhoto} alt=""/>*/}
						<div
							className='vacancy__content_right-img'
							style={{
								background: `url(${HrPhoto}) center/cover no-repeat`,
							}}
						/>
						<div className='vacancy__content_right-info'>
							<h3>{hr_name}</h3>
							<p className='vacancy__content_text'>HR Manager</p>
							<div className='vacancy__content_right-info-button'>
								<Button
									action={openModalHandler}
									text={sendCV}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{modal && (
				<>
					<div className='vacancy__modal'>
						<div
							onClick={closeModalHandler}
							className='vacancy__layout'
						/>
						<Form
							close={closeModalHandler}
							text={'Senior iOS Software Developer'}
							type={false}
						/>
					</div>
				</>
			)}
		</section>
	)
}

export default Vacancy
