import { FC } from 'react'
import './contactSection.scss'
import Form from '../Form'
import { useRecoilState } from 'recoil'
import { ThemeState } from '../../store/ThemeState'
import ContactDetails from './ContactDetails'

interface IContactSection {
	title: string
	type: boolean
	children: any
	text: string
}

const ContactSection: FC<IContactSection> = ({
	title,
	children,
	type,
	text,
}) => {
	const arr = title.split('?')
	const [them] = useRecoilState(ThemeState)
	return (
		<section id={'contactSection'} className='contactSection'>
			<div className='container'>
				{/*<SectionTitle text={title}/>*/}
				{/* <h1
					style={{
						fontWeight: 700,
						fontSize: '42px',
						lineHeight: '120%',
						textAlign: 'start',
						color: them === 'light' ? 'black' : '#FFFFFF',
						alignSelf: 'center',
					}}
				>
					{arr[0]}?
				</h1> */}
				<h1
					style={{
						fontWeight: 700,
						fontSize: '42px',
						lineHeight: '120%',
						textAlign: 'start',
						color: them === 'light' ? 'black' : '#FFFFFF',
						alignSelf: 'center',
						marginBottom:'40px'
					}}
				>
					{arr[1]}
				</h1>
				<div className={'contactSection__main'}>
					<div className='contactSection__main_formDiv'>
						{
							type ? <>
								<span className={'contact_us_subtite'} style={{margin:'50px 0 25px 0' , lineHeight:'30px' }}>{text}</span>
								<Form type={type} />
							</> : <ContactDetails />
						}
					</div>
					<div className='contactSection__right'>
						{children}
					</div>
				</div>
			</div>
		</section>
	)
}

export default ContactSection
