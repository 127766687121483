import React from 'react';

const Telephone = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.33333 3.25H8.125C8.72083 3.25 9.20833 3.7375 9.20833 4.33333C9.20833 5.6875 9.425 6.9875 9.82583 8.20083C9.945 8.58 9.85833 9.0025 9.555 9.30583L7.17167 11.6892C8.73167 14.755 11.245 17.2575 14.3108 18.8283L16.6942 16.445C16.9108 16.2392 17.1817 16.1308 17.4633 16.1308C17.5717 16.1308 17.6908 16.1417 17.7992 16.185C19.0125 16.5858 20.3233 16.8025 21.6667 16.8025C22.2625 16.8025 22.75 17.29 22.75 17.8858V21.6667C22.75 22.2625 22.2625 22.75 21.6667 22.75C11.4942 22.75 3.25 14.5058 3.25 4.33333C3.25 3.7375 3.7375 3.25 4.33333 3.25ZM7.085 5.41667C7.15 6.38083 7.3125 7.32333 7.5725 8.2225L6.2725 9.5225C5.82833 8.2225 5.54667 6.84667 5.44917 5.41667H7.085ZM17.7667 18.4383C18.6875 18.6983 19.63 18.8608 20.5833 18.9258V20.54C19.1533 20.4425 17.7775 20.1608 16.4667 19.7275L17.7667 18.4383Z"
                  fill="#4583E2"/>
        </svg>
    );
};

export default Telephone;