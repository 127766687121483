import React from 'react';
import './services.scss';
import Code from '../../../../assets/icons/code.png';
import Mobile from '../../../../assets/icons/layer.png';
import ChatBot from '../../../../assets/icons/chatbot.png';
import ServicesCard from "../../../../components/ServicesCard";
import SectionTitle from "../../../../components/SectionTitle";
import {useTranslation} from "react-i18next";

const ServicesSection = () => {

    const {t}: any = useTranslation();

    return (
        <section id={"services"} className={"services"}>
            <div className="container">
                <SectionTitle text={t('HomeServicesTitle')}/>
                <div className="services__row">
                    <ServicesCard title={t("HomeServicesCard1")} type={"first"} img={Code}
                                  subtitle={t("HomeServicesCard1Sub")}/>
                    <ServicesCard title={t("HomeServicesCard2")} type={"second"} img={Mobile}
                                  subtitle={t("HomeServicesCard2Sub")}/>
                    <ServicesCard title={t("HomeServicesCard3")} type={"third"} img={ChatBot}
                                  subtitle={t("HomeServicesCard3Sub")}/>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;