import React from 'react';
import './benefits.scss';
import SectionTitle from "../../../../components/SectionTitle";
import NumberCounter from "../../../../components/AnimatedNumber/NumberCounter";
import {useTranslation} from "react-i18next";

const BenefitsSection = () => {

    const {t}: any = useTranslation();

    const cardArr = [
        {title: t('BenefitsSectionCard1Title'), value: "3", benefit: t('BenefitsSectionCard1Subtitle')},
        {title: t('BenefitsSectionCard2Title'), value: "10", benefit: t('BenefitsSectionCard2Subtitle')},
        {title: t('BenefitsSectionCard3Title'), value: "20", benefit: t('BenefitsSectionCard3Subtitle')},
        {title: t('BenefitsSectionCard4Title'), value: "5", benefit: t('BenefitsSectionCard4Subtitle')},
    ];
    return (
        <section className={"benefits"}>
            <div className="container">
                <SectionTitle text={t('BenefitsSectionTitle')}/>
                <div className="benefits__row">
                    {
                        cardArr.map((card, idx) => (
                            <div key={idx} className="benefits__card">
                                <p className="benefits__card_value">
                                    <NumberCounter value={card.value}/>
                                </p>
                                <p className="benefits__card_benefit">{card.benefit}</p>
                                <h3 className="benefits__card_title">{card.title}</h3>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};

export default BenefitsSection;