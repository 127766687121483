import { FC } from 'react'
import './contactStep.scss'

interface IContactStep {
	number: string | number
	title: string
	subtitle: string
}

const ContactStep: FC<IContactStep> = ({ number, title, subtitle }) => {
	return (
		<div className='contactStep'>
			<p className='contactStep__number'>{number}</p>
			<div className='contactStep__info'>
				<h5 className='contactStep__title'>{title}</h5>
				<p className='contactStep__subtitle'>{subtitle}</p>
			</div>
		</div>
	)
}

export default ContactStep
