import React from 'react';

const Linkedin = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_190_2149)">
                <path d="M10.1776 25.3335V11.8395H5.60383V25.3335H10.1776ZM7.89129 9.99606C9.48623 9.99606 10.479 8.95988 10.479 7.665C10.4493 6.34092 9.4863 5.3335 7.92156 5.3335C6.35707 5.3335 5.33398 6.34094 5.33398 7.665C5.33398 8.95994 6.32651 9.99606 7.86143 9.99606H7.89115H7.89129ZM12.7091 25.3335H17.2829V17.7978C17.2829 17.3945 17.3126 16.9916 17.4333 16.7033C17.764 15.8976 18.5166 15.063 19.78 15.063C21.4351 15.063 22.0972 16.3004 22.0972 18.1144V25.3334H26.6706V17.5961C26.6706 13.4513 24.4142 11.5227 21.4049 11.5227C18.9376 11.5227 17.8543 12.8752 17.2524 13.7963H17.283V11.8393H12.7092C12.7692 13.1055 12.7092 25.3332 12.7092 25.3332L12.7091 25.3335Z" fill="#4583E2"/>
            </g>
            <defs>
                <clipPath id="clip0_190_2149">
                    <rect width="21.3333" height="20" fill="white" transform="translate(5.33398 5.3335)"/>
                </clipPath>
            </defs>
        </svg>


    );
};

export default Linkedin;