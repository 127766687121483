import './journeySection.scss'
import JourneyItem from '../../../../components/JourneyItem'
import SectionTitle from '../../../../components/SectionTitle'
import { useTranslation } from 'react-i18next'

const JourneySection = () => {
	const { t } = useTranslation()
	return (
		<section className='journeySection'>
			<div className='container'>
				<SectionTitle text={t('AboutOurJourney')} />
				<div className='journeySection__row'>
					<JourneyItem date={'2019'} text={t('About2019')} />
					<JourneyItem date={'2020'} text={t('About2020')} />
					<JourneyItem date={'2021'} text={t('About2021')} />
					<JourneyItem date={'2022'} text={t('About2022')} />
					<JourneyItem date={'2023'} text={t('About2023')} />
				</div>
			</div>
		</section>
	)
}

export default JourneySection
