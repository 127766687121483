import './teamSection.scss'
import { Pagination } from 'swiper'
import SwiperButtons from '../../../../components/SwiperButtons'
import { Swiper, SwiperSlide } from 'swiper/react'
import TeamCard from '../../../../components/TeamCard'
import ValeriaAva from '../../../../assets/team/Valeria.png'
import Nurmuhammed from '../../../../assets/team/Nurmuhammed.png'
import Felix from '../../../../assets/team/Felix.jpg'
import Alex from '../../../../assets/team/Alexander.jpg'
import Syimyik from '../../../../assets/team/Syimik.jpeg'
import Azhybek from '../../../../assets/img/Azhybek.jpg'
import Meerim from '../../../../assets/img/Meerim.jpg'
import Uluk from '../../../../assets/img/Uluk.jpg'
import AdiletTab from '../../../../assets/img/Adilet.jpg'

import SectionTitle from '../../../../components/SectionTitle'
import { useTranslation } from 'react-i18next'

const TeamSection = () => {
	const { t } = useTranslation()
	return (
		<section className='teamSection'>
			<div className='container'>
				<SectionTitle text={t('AboutOurTeam')} />
				<div className='teamSection__swiper'>
					<Swiper
						loop={true}
						pagination={true}
						slidesPerView={4}
						modules={[Pagination]}
						spaceBetween={30}
						breakpoints={{
							996: {
								slidesPerView: 4,
							},
							576: {
								slidesPerView: 2,
							},
							320: {
								slidesPerView: 1,
							},
						}}
					>
						<SwiperButtons />
						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/azhybek-sheranov/'
								}
								img={Azhybek}
								name={t('Azhybek')}
								position={'CEO'}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/valeriia-nabeeva-53ba34b7/'
								}
								img={ValeriaAva}
								name={t('ValeriaNabaeeva')}
								position={'Project Manager'}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/meerim-tynchtykova-3481641b1/'
								}
								img={Meerim}
								name={t('Meerim')}
								position={'UX/UI Designer'}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/ulukmyrza-zhanybekov-54905b23a/'
								}
								img={Uluk}
								name={t('Ulukmyrza')}
								position={'Frontend Developer'}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/adilet-tabyldyev-1378a021b/'
								}
								img={AdiletTab}
								name={t('AdiletTab')}
								position={'Sales Manager'}
							/>
						</SwiperSlide>


						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/nurmuhammed-babaev-6b35b4217/'
								}
								img={Nurmuhammed}
								name={t('NurmuhammedBabaev')}
								position={'Backend Developer'}
							/>
						</SwiperSlide>
						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/feliks-bekeshov-3b6785112/'
								}
								img={Felix}
								name={t('FelixBekeshov')}
								position={'Backend Developer'}
							/>
						</SwiperSlide>

						<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/alex-kamnev-4b08b4220/'
								}
								img={Alex}
								name={t('AlexKamnev')}
								position={'Backend Developer'}
							/>
						</SwiperSlide>		<SwiperSlide>
							<TeamCard
								linkedin={
									'https://www.linkedin.com/in/syimyk-zhetibaev-154053233/'
								}
								img={Syimyik}
								name={t('SyimykZhetbaev')}
								position={'Frontend Developer'}
							/>
						</SwiperSlide>
					</Swiper>
				</div>
			</div>
		</section>
	)
}

export default TeamSection
