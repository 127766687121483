import React from 'react';
import EneImg from '../../../../assets/img/new-logo-enebalaga.png';
import Osma from '../../../../assets/img/new-logo-osma.png';
import PhonoImg from '../../../../assets/img/new-logo-phono.png';
import './OurClients.scss'
import {Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/navigation";
import "swiper/css";
import ClientReview from "../../../../components/ClientReview/ClientReview";
import SwiperButtons from "../../../../components/SwiperButtons";
import SectionTitle from "../../../../components/SectionTitle";
import {useTranslation} from "react-i18next";

const Index = () => {
    const { t } = useTranslation()
    const dataTestimonials = [
        {
            img: EneImg,
            mess:t("OurClientsSectionCard1Mess"),
            author: t("OurClientsSectionCard1Company")
        },
        {
            img: Osma,
            mess: t("OurClientsSectionCard2Mess"),
            company: t('OurClientsSectionCard2Company')
        },
        {
            img: PhonoImg,
            mess: t("OurClientsSectionCard3Mess"),
            company: t("OurClientsSectionCard3Company"),
            author: t("OurClientsSectionCard3Author")
        }

    ]
    return (
        <section className={"homeOurClients"}>
            <div className={"container"}>
                <SectionTitle text={t("OurClientsSectionTitle")}/>
                <div className="homeOurClients__main">
                    <Swiper loop={true} spaceBetween={25} slidesPerView={1}
                            pagination={true}
                            modules={[Pagination]}
                            className="mySwiper">
                        <SwiperButtons/>
                        {
                            dataTestimonials.map((card) => (
                                <SwiperSlide>
                                    <ClientReview card={card}
                                                  url={'https://ru-static.z-dn.net/files/ddd/02bd3a23f077cda4cc1843b6467a4db1.jpg'}/>
                                </SwiperSlide>
                            ))
                        }

                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Index;