import React, {FC} from 'react'
import Button from '../Button'

interface IContactData {
    icon: SVGElement
    text: string
    link: string
}

const contactData = [
    {
        icon: (
            <svg
                width='18'
                height='18'
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.61 11.99 12.86 11.89 13.12 11.89C13.22 11.89 13.33 11.9 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0ZM3.54 2C3.6 2.89 3.75 3.76 3.99 4.59L2.79 5.79C2.38 4.59 2.12 3.32 2.03 2H3.54ZM13.4 14.02C14.25 14.26 15.12 14.41 16 14.47V15.96C14.68 15.87 13.41 15.61 12.2 15.21L13.4 14.02Z'
                    fill='#4583E2'
                />
            </svg>
        ),
        text: '+996 998 446 610',
        link: 'tel:+996998441610',
    },
    {
        icon: (
            <svg
                width='20'
                height='16'
                viewBox='0 0 20 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M1.3749 6.8879C6.74359 4.62993 10.3236 3.14134 12.1148 2.42212C17.2292 0.36862 18.2919 0.0119045 18.9846 0.00012559C19.1369 -0.00246506 19.4776 0.0339819 19.6982 0.206816C19.8845 0.352754 19.9358 0.549895 19.9603 0.68826C19.9848 0.826626 20.0154 1.14183 19.9911 1.38811C19.714 4.1992 18.5147 11.021 17.9046 14.1694C17.6465 15.5017 17.1382 15.9484 16.6461 15.9921C15.5766 16.0871 14.7645 15.3098 13.7287 14.6543C12.1078 13.6287 11.1921 12.9902 9.61879 11.9893C7.80053 10.8327 8.97923 10.1969 10.0154 9.15799C10.2866 8.88609 14.9987 4.74867 15.0899 4.37335C15.1013 4.32641 15.1119 4.15143 15.0042 4.05904C14.8965 3.96664 14.7376 3.99824 14.6229 4.02337C14.4604 4.05898 11.871 5.71112 6.85486 8.97978C6.11988 9.46697 5.45416 9.70435 4.8577 9.69191C4.20015 9.6782 2.93528 9.33301 1.99498 9.03795C0.84166 8.67605 -0.074973 8.48471 0.00484519 7.87009C0.0464194 7.54996 0.503103 7.22256 1.3749 6.8879Z'
                    fill='#4583E2'
                />
            </svg>
        ),
        text: '@fortysales',
        link: 'tg://resolve?domain=fortysales',
    },
    {
        icon: (
            <svg
                width='20'
                height='16'
                viewBox='0 0 20 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M18 0H2C0.9 0 0.01 0.9 0.01 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM2 4L10 9L18 4V14H2V4ZM2 2L10 7L18 2H2Z'
                    fill='#4583E2'
                />
            </svg>
        ),
        text: 'people@fortylines.io',
        link: 'mailto:people@fortylines.io',
    },
]

const ContactDetails = () => {
    return (
        <div className='details_wrapper'>
            <div className='detail_title'>
                Want to become a part of Fortylines team?{' '}
            </div>
            <div className='details_info'>
                <div className='details_info_title'>
                    Fortylines team contact details
                </div>
                <div className='subtitle'>Contact person:</div>
                <div className='subtitle subtitle_person'>Azhibek Sheranov</div>
            </div>
            <div className='details_contacts'>
                {contactData.map((item) => {
                    return (
                        <div className='details_contacts_item'>
                            <div className='icon'>{item.icon}</div>
                            <div className='text'>
                                <a href={item.link}>{item.text}</a>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='details_button'>
                <a href={'tg://resolve?domain=fortysales'} target={'_blank'} >
                    <Button text={'Send CV through Telegram'}/>
                </a>
            </div>
        </div>
    )
}

export default ContactDetails
