import Planet from '../../../../assets/img/planet.png';
import Planet2 from '../../../../assets/img/planet2.png';
import IntroSection from "../../../../components/IntroSection";
import {useRecoilState} from "recoil";
import {ThemeState} from "../../../../store/ThemeState";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import { Typewriter } from 'react-simple-typewriter'

const HomeIntro = () => {
    const [theme] = useRecoilState(ThemeState);
    const {t}: any = useTranslation();
    const navigate = useNavigate();
    const navigateHandler = () => {
        navigate('/start')
    };

    return (
        <IntroSection action={navigateHandler} actionText={t("homeButton")}
                      img={theme === 'dark' ? Planet : Planet2}
                      subtitle={t('homeCompany')}>
            <h1 className="introSection__title">
                {t("homeTitle")} <span className="introSection__title_blue">{t("homeTitleBlue")}</span> <br/> <Typewriter loop={true} words={[t('homeAnimation')]} />
            </h1>
        </IntroSection>
    );
};

export default HomeIntro;