import React, {FC} from 'react';
import './button.scss';

interface ButtonInterface {
    text: string,
    type?: any,
    action?: any
}

const Button: FC<ButtonInterface> = ({text, action, type}) => {
    return (
        <button type={type ? type : "button"} onClick={() => action ? action() : null} className={"button"}>
            {text}
        </button>
    );
};

export default Button;