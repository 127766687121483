import GetStarted from '../../../../components/GetStartedSection'

const Index = () => {
	return (
		<section style={{ marginTop: '30px' }} className={'getStarted'}>
			<div className={'container'}>
				<GetStarted />
			</div>
		</section>
	)
}

export default Index
