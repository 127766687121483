import React from 'react'
import './findJob.scss'
import FindJobImg from '../../../../assets/img/find-job.png'

const FindJob = () => {
	return (
		<section className='find_job_wrapper'>
			<div className='container'>
				<div className='find_job_content'>
					<div className='find_job_info'>
						<div className='title'>Find Job </div>
						<div className='subtitle'>
							Apply to our hot jobs through the TapInTech
							application!
						</div>
						<div className='subtitle subtitle_bottom'>
							Send your CV and follow up on your application
							status online
						</div>
						<a
							target={'_blank'}
							className='ProjectCard__info_link'
							href={'https://tapintech.io/applications?page=1'}
							rel='noreferrer'
						>
							<span>
								<svg
									width='28'
									height='6'
									viewBox='0 0 28 6'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M28 3L23 0.113249V5.88675L28 3ZM0 3.5H23.5V2.5H0V3.5Z'
										fill='#4583E2'
									/>
								</svg>
							</span>
							See Open Positions
						</a>
					</div>
					<div className='img_wrapper'>
						<img src={FindJobImg} alt='img' />
					</div>
				</div>
			</div>
		</section>
	)
}

export default FindJob
