import React from 'react';

const Mail = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M21.666 4.3335H4.33268C3.14102 4.3335 2.17685 5.3085 2.17685 6.50016L2.16602 19.5002C2.16602 20.6918 3.14102 21.6668 4.33268 21.6668H21.666C22.8577 21.6668 23.8327 20.6918 23.8327 19.5002V6.50016C23.8327 5.3085 22.8577 4.3335 21.666 4.3335ZM4.33268 8.66683L12.9993 14.0835L21.666 8.66683V19.5002H4.33268V8.66683ZM4.33268 6.50016L12.9993 11.9168L21.666 6.50016H4.33268Z"
                  fill="#4583E2"/>
        </svg>

    );
};

export default Mail;