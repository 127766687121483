/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import 'remixicon/fonts/remixicon.css'
import './App.css'
import './utils/i18'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Home from './pages/Home'
import PopUp from './components/PopUp'
import { useRecoilState } from 'recoil'
import { messageState, modalState } from './store/states'
import { ThemeState } from './store/ThemeState'

import './styles/LightTheme/LightTheme.scss'
import './styles/LightTheme/LightAbout.scss'
import './styles/LightTheme/LightCareers.scss'
import './styles/LightTheme/LightVacancy.scss'
import './styles/LightTheme/LightBurger.scss'
import About from './pages/About'
import Careers from './pages/Careers'
import Vacancy from './pages/Vacancy'
import GetStarted from './pages/GetStarted'
import Layout from './components/Layout'
import './styles/media/1200media.scss'
import './styles/media/996media.scss'
import './styles/media/768media.scss'
import './styles/media/576media.scss'
import './styles/media/425media.scss'

function App() {
	const [popUpMes] = useRecoilState(messageState)
	const [modal] = useRecoilState(modalState)
	const [theme] = useRecoilState(ThemeState)
	const [introState, setIntroState] = useState(true)

	const el: any = document.querySelector('body')

	let scrollHeight = Math.max(
		document.body.scrollHeight,
		document.documentElement.scrollHeight,
		document.body.offsetHeight,
		document.documentElement.offsetHeight,
		document.body.clientHeight,
		document.documentElement.clientHeight,
	)

	useEffect(() => {
		if (theme === 'light') {
			el.classList.add('LightTheme')
		} else {
			el.classList.remove('LightTheme')
		}
	}, [theme])

	useEffect(() => {
		if (modal) {
			el.style.overflow = 'hidden'
		} else {
			el.style.overflow = 'auto'
		}
	}, [modal])

	useEffect(() => {
		setInterval(() => {
			setIntroState(false)
		}, 3500)
	}, [])

	const location = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location.pathname])

	return (
		<>
			{popUpMes ? <PopUp /> : ''}
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route path='/' element={<Navigate to='/home' replace />} />
					<Route path='/home' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/careers' element={<Careers />} />
					<Route path='/careers/:id' element={<Vacancy />} />
				</Route>
				<Route path='/start' element={<GetStarted />} />
			</Routes>
		</>
	)
}

export default App
