import React from 'react';

const Instagram = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_190_2145)">
                <path d="M16.0051 10.6093C12.9765 10.6093 10.5336 13.014 10.5336 15.9952C10.5336 18.9765 12.9765 21.3811 16.0051 21.3811C19.0336 21.3811 21.4765 18.9765 21.4765 15.9952C21.4765 13.014 19.0336 10.6093 16.0051 10.6093ZM16.0051 19.4968C14.0479 19.4968 12.4479 17.9265 12.4479 15.9952C12.4479 14.064 14.0432 12.4936 16.0051 12.4936C17.967 12.4936 19.5622 14.064 19.5622 15.9952C19.5622 17.9265 17.9622 19.4968 16.0051 19.4968V19.4968ZM22.9765 10.389C22.9765 11.0874 22.4051 11.6452 21.7003 11.6452C20.9908 11.6452 20.4241 11.0827 20.4241 10.389C20.4241 9.69521 20.9955 9.13271 21.7003 9.13271C22.4051 9.13271 22.9765 9.69521 22.9765 10.389ZM26.6003 11.664C26.5193 9.98115 26.1289 8.49053 24.8765 7.2624C23.6289 6.03428 22.1146 5.6499 20.4051 5.56553C18.6432 5.46709 13.3622 5.46709 11.6003 5.56553C9.89555 5.64521 8.38127 6.02959 7.12889 7.25771C5.87651 8.48584 5.49079 9.97646 5.40508 11.6593C5.30508 13.3936 5.30508 18.5921 5.40508 20.3265C5.48603 22.0093 5.87651 23.4999 7.12889 24.728C8.38127 25.9561 9.89079 26.3405 11.6003 26.4249C13.3622 26.5233 18.6432 26.5233 20.4051 26.4249C22.1146 26.3452 23.6289 25.9608 24.8765 24.728C26.1241 23.4999 26.5146 22.0093 26.6003 20.3265C26.7003 18.5921 26.7003 13.3983 26.6003 11.664V11.664ZM24.3241 22.1874C23.9527 23.1061 23.2336 23.814 22.2955 24.1843C20.8908 24.7327 17.5574 24.6061 16.0051 24.6061C14.4527 24.6061 11.1146 24.728 9.7146 24.1843C8.78127 23.8186 8.06222 23.1108 7.68603 22.1874C7.12889 20.8046 7.25746 17.5233 7.25746 15.9952C7.25746 14.4671 7.13365 11.1811 7.68603 9.80303C8.05746 8.88428 8.7765 8.17646 9.7146 7.80615C11.1194 7.25771 14.4527 7.38428 16.0051 7.38428C17.5574 7.38428 20.8955 7.2624 22.2955 7.80615C23.2289 8.17178 23.9479 8.87959 24.3241 9.80303C24.8813 11.1858 24.7527 14.4671 24.7527 15.9952C24.7527 17.5233 24.8813 20.8093 24.3241 22.1874Z" fill="#4583E2"/>
            </g>
            <defs>
                <clipPath id="clip0_190_2145">
                    <rect width="21.3333" height="24" fill="white" transform="translate(5.33398 4)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Instagram;