import React from 'react'
import './OurProjects.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper'
import ProjectCard from '../../../../components/ProjectCard/ProjectCard'
import TezBus from '../../../../assets/img/new1-tezbus.png'
import Atelier from '../../../../assets/img/new1-atelier.png'
import Tapintech from '../../../../assets/img/new1-tapintech.png'
import EneBalaga from '../../../../assets/img/new1-enebalaga.png'
import EQMS from '../../../../assets/img/new1-eqmis.png'
import Phono from '../../../../assets/img/new1-phonelive.png'
import SwiperButtons from '../../../../components/SwiperButtons'
import SectionTitle from '../../../../components/SectionTitle'
import { useTranslation } from 'react-i18next'

const Index = () => {
	const { t } = useTranslation()

	const projectsData = [
		{
			name: 'Ene Balaga Tirek',
			img: EneBalaga,
			description: t('OurProjectsSectionProject3Desc'),
			type: t('OurProjectsSectionProject3Type'),
			stacks: ['Java', 'React', 'JavaScript'],
			url: 'https://enebalagatirek.org',
		},
		{
			name: 'EQMIS',
			img: EQMS,
			description: t('OurProjectsSectionProject2Desc'),
			type: t('OurProjectsSectionProject2Type'),
			stacks: ['Java', 'Thymeleaf', 'JavaScript'],
		},
		{
			name: 'Phono Live',
			img: Phono,
			description: t('OurProjectsSectionProject4Desc'),
			type: t('OurProjectsSectionProject4Type'),
			stacks: ['Java', 'JavaScript', 'React', 'TypeScript'],
			url: 'https://app.phono.live',
		},
		{
			name: 'Tezbus',
			img: TezBus,
			description: t('OurProjectsSectionProject1Desc'),
			type: t('OurProjectsSectionProject1Type'),
			stacks: ['Java', 'React', 'Go'],
		},
		{
			name: 'Nabi Fashion',
			img: Atelier,
			description: t('OurProjectsSectionProject5Desc'),
			type: t('OurProjectsSectionProject5Type'),
			stacks: ['Next Js', 'React', 'HTML', 'CSS', 'JS'],
			url: 'https://atelier.nabi-berlin.de/',
		},
		{
			name: 'Tapintech',
			img: Tapintech,
			description: t('OurProjectsSectionProject6Desc'),
			type: t('OurProjectsSectionProject6Type'),
			stacks: ['Java', 'React', 'HTML', 'CSS', 'JS', 'Redux Toolkit'],
			url: 'https://tapintech.io/',
		},
	]

	return (
		<section id={'projects'} className={'homeOurProjects'}>
			<div
				style={{ marginBottom: '140px !important' }}
				className={'container'}
			>
				<SectionTitle text={t('OurProjectsSectionTitle')} />
				<div className={'SwiperContainer'}>
					<Swiper
						loop={true}
						pagination={true}
						modules={[Pagination]}
					>
						<SwiperButtons />
						{projectsData.map((el: any) => (
							<SwiperSlide>
								<ProjectCard
									img={el.img}
									des={el.description}
									type={el.type}
									stacks={el.stacks}
									name={el.name}
									url={el.url}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	)
}

export default Index
