export const vacanciesData = {
    En:[
        {
            position: 'Senior iOS Software Developer',
            type: 'Remote, Germany',
            techs: ['iOS', 'swift', 'Git', 'UIKit', 'Kanban', 'Scrum', 'CocoaPods', 'MVC', 'MVVM'],
            requirements: [
                'At least 3 years of work experience in a similar position;',
                'solid knowledge of the pros and cons of various architectures: MVP, MVC, MVVM and VIPER. The ability to choose and apply in practice.',
                '(CoreData || Realm), UIKit, Networking, CocoaPods', 'Automatic display and Storyboard'
            ],
            offers: [
                'Professional development, training at the expense of the company;',
                'Prospects for career growth;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
            resp: [
                'implementation of interaction with external services, extension of existing REST APIs',
                'fixing bugs in developed services detected as a result of testing and pilot operation;',
                'interacting with customers as part of projects;',
                'survey of the subject area, development and approval of working documentation',
            ],
        },
        {
            position: 'Middle Java Developer',
            type: 'Remote, Germany',
            techs: ['Java SE 8+', 'OOP', 'Git', 'SQL', 'Maven/Gradle', 'Spring/Java EE', 'JUnit/TestNG'],
            requirements: [
                'Confident knowledge of Java SE 8+;',
                'Knowledge of OOP principles, OOP design patterns;',
                'Understanding of microservice architecture;',
                'Experience in enterprise design/development of heavy-duty services;',
                'Knowledge of SQL;',
                'Knowledge of Maven/Gradle;',
                'Knowledge of Git;',
                'Experience with Spring/Java EE;',
                'Knowledge of JUnit/TestNG (Will be a plus);',
                'Experience with Kafka, RabbitMQ (Will be a plus).',
            ],
            offers: [
                'Professional development, training at the expense of the company;',
                'Career Growth Prospects;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
            resp: [
                'implementation of interaction with external services, extension of existing REST APIs',
                'fixing bugs in developed services detected as a result of testing and pilot operation;',
                'participation in the formation of the work plan;',
                'interacting with customers as part of projects;',
                'survey of the subject area, development and approval of working documentation',
            ]
        },
        {
            position: 'Middle Front-end Developer',
            type: 'Remote, Germany',
            techs: ['ReactJS', 'Node.js', 'Git', 'HTML5', 'CSS3', 'Docker', 'Git'],
            requirements: [
                'Professional education (higher education or courses in programming);',
                'At least 2 years of work experience in a similar position;',
                'Good knowledge of JavaScript (ReactJS, Node.js), HTML5, CSS3;',
                'Experience in adaptive and cross-browser layout;',
                'Ability to work with git, Docker;',
                'Understanding of client-server interaction;',
            ],
            resp: [
                'adaptive and cross-browser layout of websites;',
                'creation of new projects and revision of existing ones;',
                'working in the React/Redux stack;',
                'conducting refactoring of existing projects;',
                'technical evaluation of requirements, interaction with the development team;',
            ],
            offers: [
                'Professional development, training at the expense of the company;',
                'Career Growth Prospects;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
        },
        {
            position: 'Senior Front-end Developer',
            type: 'Remote, Germany',
            techs: ['ReactJS', 'Node.js', 'Git', 'HTML5', 'CSS3', 'Docker', 'Git'],
            requirements: [
                'Good knowledge of JavaScript (ReactJS, Node.js), HTML5, CSS3;',
                'At least 5 years of work experience in a similar position;',
                'Experience in adaptive and cross-browser layout;',
                'Experience in adaptive and cross-browser layout;',
                'Ability to work with git, Docker;',
                'Understanding of client-server interaction;',
            ],
            resp: [
                'Develop new applications and web services (back-end development)',
                'Provide enhancements to existing billing and e-commerce applications',
                'Perform some front-end development',
                'Triage production issues, assist with integration support, and understand new requirements',
                'Prepare and maintain technical documentation',
                'Ensure system availability',
            ],
            offers: [
                'Professional development, training at the company\'s expense;',
                'Career Growth Prospects;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
        }
    ],
    De:[
        {
            position: 'Senior iOS Software Developer',
            type: 'Remote, Germany',
            techs: ['iOS', 'swift', 'Git', 'UIKit', 'Kanban', 'Scrum', 'CocoaPods', 'MVC', 'MVVM'],
            requirements: [
                'At least 3 years of work experience in a similar position;',
                'solid knowledge of the pros and cons of various architectures: MVP, MVC, MVVM and VIPER. The ability to choose and apply in practice.',
                '(CoreData || Realm), UIKit, Networking, CocoaPods', 'Automatic display and Storyboard'
            ],
            offers: [
                'Professional development, training at the expense of the company;',
                'Prospects for career growth;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
            resp: [
                'implementation of interaction with external services, extension of existing REST APIs',
                'fixing bugs in developed services detected as a result of testing and pilot operation;',
                'interacting with customers as part of projects;',
                'survey of the subject area, development and approval of working documentation',
            ],
        },
        {
            position: 'Middle Java Developer',
            type: 'Remote, Germany',
            techs: ['Java SE 8+', 'OOP', 'Git', 'SQL', 'Maven/Gradle', 'Spring/Java EE', 'JUnit/TestNG'],
            requirements: [
                'Confident knowledge of Java SE 8+;',
                'Knowledge of OOP principles, OOP design patterns;',
                'Understanding of microservice architecture;',
                'Experience in enterprise design/development of heavy-duty services;',
                'Knowledge of SQL;',
                'Knowledge of Maven/Gradle;',
                'Knowledge of Git;',
                'Experience with Spring/Java EE;',
                'Knowledge of JUnit/TestNG (Will be a plus);',
                'Experience with Kafka, RabbitMQ (Will be a plus).',
            ],
            offers: [
                'Professional development, training at the expense of the company;',
                'Career Growth Prospects;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
            resp: [
                'implementation of interaction with external services, extension of existing REST APIs',
                'fixing bugs in developed services detected as a result of testing and pilot operation;',
                'participation in the formation of the work plan;',
                'interacting with customers as part of projects;',
                'survey of the subject area, development and approval of working documentation',
            ]
        },
        {
            position: 'Middle Front-end Developer',
            type: 'Remote, Germany',
            techs: ['ReactJS', 'Node.js', 'Git', 'HTML5', 'CSS3', 'Docker', 'Git'],
            requirements: [
                'Professional education (higher education or courses in programming);',
                'At least 2 years of work experience in a similar position;',
                'Good knowledge of JavaScript (ReactJS, Node.js), HTML5, CSS3;',
                'Experience in adaptive and cross-browser layout;',
                'Ability to work with git, Docker;',
                'Understanding of client-server interaction;',
            ],
            resp: [
                'adaptive and cross-browser layout of websites;',
                'creation of new projects and revision of existing ones;',
                'working in the React/Redux stack;',
                'conducting refactoring of existing projects;',
                'technical evaluation of requirements, interaction with the development team;',
            ],
            offers: [
                'Professional development, training at the expense of the company;',
                'Career Growth Prospects;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
        },
        {
            position: 'Senior Front-end Developer',
            type: 'Remote, Germany',
            techs: ['ReactJS', 'Node.js', 'Git', 'HTML5', 'CSS3', 'Docker', 'Git'],
            requirements: [
                'Good knowledge of JavaScript (ReactJS, Node.js), HTML5, CSS3;',
                'At least 5 years of work experience in a similar position;',
                'Experience in adaptive and cross-browser layout;',
                'Experience in adaptive and cross-browser layout;',
                'Ability to work with git, Docker;',
                'Understanding of client-server interaction;',
            ],
            resp: [
                'Develop new applications and web services (back-end development)',
                'Provide enhancements to existing billing and e-commerce applications',
                'Perform some front-end development',
                'Triage production issues, assist with integration support, and understand new requirements',
                'Prepare and maintain technical documentation',
                'Ensure system availability',
            ],
            offers: [
                'Professional development, training at the company\'s expense;',
                'Career Growth Prospects;',
                'A lot of interesting tasks;',
                'A flexible start to the working day;',
                'Stability, the company is resistant to all changes in the market;',
                'Registration in the state under the Labor Code of the Kyrgyz Republic;',
                'Long-term project, full immersion in product development.',
            ],
        }
    ],
    Ru:[
        {
            position: 'Senior iOS Software Developer',
            type: ' Удаленное, Германия',
            techs: ['iOS', 'swift', 'Git', 'UIKit', 'Kanban', 'Scrum', 'CocoaPods', 'MVC', 'MVVM'],
            requirements: [
                'Опыт работы на аналогичной должности не менее 3 лет',
                'твердые знания плюсов и минусов различных архитектур: MVP, MVC',
                '(CoreData || Realm), UIKit, Networking, CocoaPods',
                'Автоматическое отображение и Storyboard',
            ],
            offers: [
                'Профессиональное развитие, обучение за счет компании;',
                ' Перспективы карьерного роста;',
                ' Множество интересных задач;',
                'Гибкое начало рабочего дня;',
                ' Стабильность, компания устойчива ко всем изменениям на рынке;',
                'Оформление в штат по Трудовому кодексу Кыргызской Республики;',
                'Долгосрочный проект, полное погружение в разработку продукта.',
            ],
            resp: [
                'реализация взаимодействия с внешними сервисами, расширение существующих REST API',
                'исправление ошибок в разработанных сервисах, выявленных в результате тестирования и опытной эксплуатации',
                'участие в формировании плана работ',
                'взаимодействие с заказчиками в рамках проектов',
                'обследование предметной области, разработка и утверждение рабочей документации'
            ],
        },
        {
            position: 'Middle Java Developer',
            type: 'Удаленное, Германия',
            techs: ['Java SE 8+', 'OOP', 'Git', 'SQL', 'Maven/Gradle', 'Spring/Java EE', 'JUnit/TestNG'],
            requirements: [
                'Уверенное знание Java SE 8+;',
                'Знание принципов ООП, паттернов проектирования ООП;',
                ' Понимание микросервисной архитектуры;',
                'Опыт корпоративного проектирования/разработки высоконагруженных сервисов;',
                'Знание SQL;',
                'Знание Maven/Gradle;',
                ' Знанbе Git;',
                ' Опыт работы с Spring/Java EE;',
                'Знание JUnit/TestNG (будет плюсом);'
            ],
            offers: [
                'реализация взаимодействия с внешними сервисами, расширение существующих REST API',
                'исправление ошибок в разработанных сервисах, выявленных в результате тестирования и опытной эксплуатации;',
                'участие в формировании плана работ;',
                ' взаимодействие с заказчиками в рамках проектов;',
                ' обследование предметной области, разработка и утверждение рабочей',

            ],
            resp: [
                'Профессиональное развитие, обучение за счет компании;',
                'Перспективы карьерного роста;',
                'Множество интересных задач;',
                'Гибкое начало рабочего дня;',
                'Стабильность, компания устойчива ко всем изменениям на рынке;',
                'Оформление в штат по Трудовому кодексу Кыргызской Республики;',
                'Долгосрочный проект, полное погружение в разработку продукта.',

            ]
        },
        {
            position: 'Middle Front-end Developer',
            type: 'Удаленно Германия',
            techs: ['ReactJS', 'Node.js', 'Git', 'HTML5', 'CSS3', 'Docker', 'Git'],
            requirements: [
                'Профессиональное образование (высшее образование или курсы по программированию);',
                'Опыт работы на аналогичной должности не менее 2 лет;',
                'Хорошее знание JavaScript (ReactJS, Node.js), HTML5, CSS3;',
                'Опыт адаптивной и кроссбраузерной верстки;',
                'Умение работать с git, Docker;',
                'Понимание взаимодействия клиент-сервер;',

            ],
            resp: [
                ' адаптивная и кроссбраузерная верстка веб-сайтов;',
                'создание новых и доработка существующих проектов;',
                'работа в стеке React/Redux;',
                'проведение рефакторинга существующих проектов;',
                'техническая оценка требований, взаимодействие с командой разработчиков;',
            ],
            offers: [
                'Профессиональное развитие, обучение за счет компании;',
                'Перспективы карьерного роста;',
                ' Множество интересных задач;',
                'Гибкое начало рабочего дня;',
                'Стабильность, компания устойчива ко всем изменениям на рынке;',
                ' Оформление в штат по Трудовому кодексу Кыргызской Республики;',
                'Долгосрочный проект, полное погружение в разработку продукта.',

            ],
        },
        {
            position: 'Senior Front-end Developer',
            type: 'Удаленно Германия',
            techs: ['ReactJS', 'Node.js', 'Git', 'HTML5', 'CSS3', 'Docker', 'Git'],
            requirements: [
                'Профессиональное образование (высшее образование или курсы по программированию);',
                'Опыт работы на аналогичной должности не менее 5 лет;',
                'Хорошее знание JavaScript (ReactJS, Node.js), HTML5, CSS3;',
                'Опыт адаптивной и кроссбраузерной верстки;Умение работать с git, Docker;',
                'Понимание взаимодействия клиент-сервер;',
            ],
            resp: [
                'Разработка новых приложений и веб-сервисов (back-end разработка).',
                'Обеспечение усовершенствования существующих приложений для биллинга и электронной коммерции',
                'Выполнение некоторых работ по разработке фронтенда',
                'Решение производственных проблем, помощь в поддержке интеграции и понимание новых требований.',
                'Подготовка и ведение технической документации',
                'Обеспечение доступности системы',

            ],
            offers: [
                'Профессиональное развитие, обучение за счет компании;',
                ' Перспективы карьерного роста;',
                'Множество интересных задач;',
                ' Гибкое начало рабочего дня;',
                'Стабильность, компания устойчива ко всем изменениям на рынке;',
                'Оформление в штат по Трудовому кодексу Кыргызской Республики;',
                'Долгосрочный проект, полное погружение в разработку продукта.',

            ],
        }
    ]
}


