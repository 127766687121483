import React from 'react'
import './ClientReview.scss'
import { MdFormatQuote } from 'react-icons/md'

interface Interface {
	url: string
	card: any
}

const ClientReview: React.FC<Interface> = ({ url, card }) => {
	return (
		<div className={'testimonialsCard'}>
			<div
				style={{ backgroundImage: `url(${card.img})` }}
				className='testimonialsCard__img'
			></div>
			<div className='testimonialsCard__info'>
				<MdFormatQuote
					className='testimonialsCard__quote'
					color='#676767'
					size={40}
				/>
				<p className={'testimonialsCard__text'}>{card.mess}</p>
				<p className={'testimonialsCard__name'}>{card.author}</p>
				<p className={'testimonialsCard__subtitle'}>{card.company}</p>
			</div>
		</div>
	)
}

export default ClientReview
