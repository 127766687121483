import { FC, useState } from 'react'
import './form.scss'
import Button from '../Button'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import FilePicker from '../FilePicker'
import { inputPlacholderValue } from '../../utils/helpers'
import { sendContactForm } from '../../utils/nodemailer'
import { errorAlert, successAlert } from '../../utils/toast'

interface IForm {
	type: boolean
	text?: string
	close?: any
}

interface IInputValue {
	name: string
	email: string
	phone: string
	comment: string
}

const Form: FC<IForm> = ({ type, text, close }) => {
	const { t } = useTranslation()
	const checkbox_title: any = t('formCheckbox')
	const [loading, setLoading] = useState<boolean>(false)
	const [file, setFile] = useState<any>([])
	const [error, setError] = useState('')

	const [inputValues, setInputValues] = useState<IInputValue>({
		name: '',
		email: '',
		phone: '',
		comment: '',
	})

	const [errors, setErrors] = useState({
		name: '',
		email: '',
		phone: '',
		comment: '',
	})

	const validate = () => {
		let tempErrors: any = {}
		let isValid = true

		if (!inputValues.email) {
			tempErrors.email = 'Email is required'
			isValid = false
		}

		if (!inputValues.name) {
			tempErrors.name = 'Name is required'
			isValid = false
		}

		if (!inputValues.phone) {
			tempErrors.phone = 'Phone is required'
			isValid = false
		}

		setErrors(tempErrors)
		return isValid
	}

	const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { name } = e.target as HTMLInputElement
		const { value } = e.target as HTMLInputElement
		setInputValues({ ...inputValues, [name]: value })
		setErrors({
			...errors,
			[name]: '',
		})
	}

	const sendWithoutCV = async () => {
		setLoading(true)
		try {
			if (validate()) {
				const dataToSend = {
					...inputValues,
				}
				// await axios.defaults.headers.post["Content-Type"] = "application/json";
				const response = await axios.post(
					'https://formsubmit.co/ajax/azhybek@fortylines.io',
					dataToSend,
				)
				setLoading(false)
				setInputValues({
					name: '',
					email: '',
					phone: '',
					comment: '',
				})

				successAlert('The form was submitted successfully.')
			}
		} catch (error) {
			setLoading(false)
			errorAlert('Failed to send message')
		}
	}

	const sendWithCV = async () => {
		setLoading(true)
		try {
			if (
				!file ||
				!inputValues.name ||
				!inputValues.email ||
				!inputValues.phone
			) {
				setError('Please fill the blanks')
				return
			}
			const formData = new FormData()
			formData.append('file', file[0])
			formData.append('name', inputValues.name)
			formData.append('email', inputValues.email)
			formData.append('phone', inputValues.phone)

			const response = await axios.post(
				"https://formsubmit.co/ajax/azhybek@fortylines.io",
				// 'https://formsubmit.co/ajax/era4488era@gmail.com',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			)

			setLoading(false)
			setInputValues({
				name: '',
				email: '',
				phone: '',
				comment: '',
			})
			// setFile("");
			setFile([])
			successAlert('The form was submitted successfully.')
		} catch (error) {
			setLoading(false)
			errorAlert('Failed to send message')
		}
	}

	const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		// if (type) {
			await sendWithoutCV()
		// } else {
		// 	await sendWithCV()
		// }
	}

	return (
		<>
			{type ? (
				<form onSubmit={submitHandler} className='form'>

					<input type='hidden' name='_template' value='table' />
					<input
						type='hidden'
						name='_subject'
						value='Message from website'
					/>
					<input
						name='name'
						value={inputValues.name}
						onChange={handleChange}
						// required={true}
						placeholder={inputPlacholderValue(t('formName'))}
						type='text'
					/>
					<span className='input_error'>{errors.name}</span>
					<input
						name='email'
						value={inputValues.email}
						onChange={handleChange}
						// required={true}
						placeholder={inputPlacholderValue(t('formEmail'))}
						type='email'
					/>
					<span className='input_error'>{errors.email}</span>
					<input
						name='phone'
						value={inputValues.phone}
						onChange={handleChange}
						// required={true}
						placeholder={inputPlacholderValue(t('formPhone'))}
						type='text'
					/>
					<span className='input_error'>{errors.phone}</span>
					<input
						name='comment'
						value={inputValues.comment}
						onChange={handleChange}
						// required={true}
						placeholder={inputPlacholderValue(t('formComments'))}
						type={'text'}
					/>
					<label>
						<input required={true} type='checkbox' />
						<span>{checkbox_title}</span>
					</label>
					<Button type={'submit'} text={t('send')} />
				</form>
			) : (
				<form
					onSubmit={submitHandler}
					// method="POST"
					className='form'
					// encType="multipart/form-data"
					action='4e7eeb2f8e0577a360d072605fdd8df7 '
				>
					{text ? (
						<>
							<span onClick={close} className='form__close'>
								<svg
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill='#B6B6B6'
										d='M19.3332 2.54699L17.4532 0.666992L9.99984 8.12033L2.5465 0.666992L0.666504 2.54699L8.11984 10.0003L0.666504 17.4537L2.5465 19.3337L9.99984 11.8803L17.4532 19.3337L19.3332 17.4537L11.8798 10.0003L19.3332 2.54699Z'
									/>
								</svg>
							</span>
							<h3 className='form__title'>{text}</h3>
						</>
					) : (
						''
					)}
					<input type='hidden' name='_template' value='table' />
					<input type='hidden' name='_captcha' value='false' />
					<input
						type='hidden'
						name='_autoresponse'
						value='Thank you for your interest in our company and the vacancy! We will definitely consider your resume! Have a nice day!'
					/>
					<input
						type='hidden'
						name='_subject'
						value='Apply for job'
					/>
					<input
						type='hidden'
						name='_next'
						value='https://fortylines-web-site.vercel.app/careers'
					/>
					<input
						name='name'
						value={inputValues.name}
						onChange={handleChange}
						required={true}
						placeholder={inputPlacholderValue(t('formName'))}
						type='text'
					/>
					<input
						name='email'
						value={inputValues.email}
						onChange={handleChange}
						type='email'
						required={true}
						placeholder={inputPlacholderValue(t('formEmail'))}
					/>
					<input
						name='phone'
						value={inputValues.phone}
						onChange={handleChange}
						required={true}
						placeholder={inputPlacholderValue(t('formPhone'))}
						type='text'
					/>
					<FilePicker
						setFile={setFile}
						file={file}
						onChooseFile={(tag) => {}}
					/>
					<label>
						<input required={true} type='checkbox' />
						<p>
							<span>{checkbox_title}</span>
						</p>
					</label>
					<Button type={'submit'} text={t('sendRequest')} />
				</form>
			)}
		</>
	)
}

export default Form
