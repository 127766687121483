import React from 'react';
import './weSection.scss';
import ServicesCard from "../../../../components/ServicesCard";
import Light from '../../../../assets/icons/light.png';
import Settings from '../../../../assets/icons/passionWork.png';
import Group from '../../../../assets/icons/group.png';
import SectionTitle from "../../../../components/SectionTitle";
import {useTranslation} from "react-i18next";

const ValueSection = () => {
    const {t} = useTranslation()
    return (
        <section className="valueSection">
            <div className="container">
                <SectionTitle text={t("AboutOurValues")}/>
                <div className="valueSection__row">
                    <ServicesCard title={t("AboutProductConfidence")} type={"third"} img={Group}
                                  subtitle={t('AboutProductConfidenceText')}/>
                    <ServicesCard title={t("AboutCustomerCentriCity")} type={"first"} img={Light}
                                  subtitle={t("AboutCustomerCentriCityText")}/>
                    <ServicesCard title={t("AboutPassionAndCommitment")} type={"second"} img={Settings}
                                  subtitle={t("AboutPassionAndCommitmentText")}/>
                </div>
            </div>
        </section>
    );
};

export default ValueSection;