import React from 'react'
import './projectCard.scss'
import { useTranslation } from 'react-i18next'

interface Props {
	img: any
	des: string
	type: string
	stacks: any
	name: string
	url?: string
}

const ProjectCard: React.FC<Props> = ({
	img,
	des,
	name,
	stacks,
	type,
	url,
}) => {
	const { t } = useTranslation()
	const industry = t('industry')
	const tech_stack = t('TechnologyStack')
	const learn_more = t('LearnMoreAboutProject')
	return (
		<div className={'ProjectCard'}>
			<div className={'ProjectCard__info'}>
				<h3 className='ProjectCard__info_title'>{name}</h3>
				<p className='ProjectCard__info_description'>{des}</p>
				<p className={'ProjectCard__info_subtitle'}>{industry}:</p>
				<p className={'ProjectCard__info_text'}>{type}</p>
				<p className={'ProjectCard__info_subtitle'}>{tech_stack}:</p>
				<p className={'ProjectCard__info_text'}>
					{stacks.map((el: any, idx: number) => (
						<span>
							{el}
							{idx !== stacks.length - 1 ? ', ' : ''}
						</span>
					))}
				</p>
				{url ? (
					<a
						target={'_blank'}
						className={'ProjectCard__info_link'}
						href={url}
						rel='noreferrer'
					>
						<span>
							<svg
								width='28'
								height='6'
								viewBox='0 0 28 6'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M28 3L23 0.113249V5.88675L28 3ZM0 3.5H23.5V2.5H0V3.5Z'
									fill='#4583E2'
								/>
							</svg>
						</span>
						{learn_more}
					</a>
				) : (
					''
				)}
			</div>
			<img className={'ProjectCard__img'} src={img} alt='' />
		</div>
	)
}

export default ProjectCard
